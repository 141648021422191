import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../components/layout";
import ContactSidebar from "../components/ContactSidebar";
import AdresseSidebar from "../components/AdresseSidebar";
import Accordion from "../components/Accordion";
import AvantApresBlock from "../components/AvantApresBlock";
import KeybodyBlock from "../components/KeybodyBlock";
import VideoBlock from "../components/VideoBlock";
import NewsBlock from "../components/NewsBlock";
import ASavoir from "../components/ASavoir";
import Bio from "../components/Bio";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Social from "../components/Social";
import { FaHome } from "react-icons/fa";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import Carrousel from "../components/Carrousel";
import _ from "lodash";

export default function Template({ data }) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data

  const { frontmatter, html } = markdownRemark;

  const autresPages = _.remove(allMarkdownRemark.edges, function (arg) {
    return arg.node.frontmatter.path !== frontmatter.path;
  });

  const Wrapper = g.div({
    maxWidth: `1000px`,
    margin: `0 auto 2.5em`,
  });

  const Tag = g.div({
    textAlign: `center`,
    color: `black`,
    fontSize: `.8em`,
    fontWeight: `400`,
    marginTop: `2.4em`,
    textTransform: `uppercase`,
    letterSpacing: `.08em`,
    "@media(max-width: 767px)": {
      fontSize: `.85em`,
      marginTop: `1.6em`,
    },
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `36px`,
    color: `#3c4d68`,
    maxWidth: `720px`,
    margin: `.1em auto .6em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `25px`,
      lineHeight: `32px`,
    },
  });

  const Tags = g.div({
    marginBottom: `30px`,
    textAlign: `right`,
  });

  const TagsItem = g.span({
    display: `inline-block`,
    textTransform: `capitalize`,
    "&::after": {
      color: `#333`,
      paddingRight: `3px`,
    },
    "&::last-of-type": {
      color: `red`,
    },
    ":hover": {
      textDecoration: `underline`,
    },
    ":focus": {},
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  css.global(".gatsby-resp-image-figcaption", {
    textAlign: "center",
    fontStyle: `italic`,
    fontSize: `.85em`,
    lineHeight: `1.45em`,
    marginTop: `.5em`,
  });

  css.insert('.tagsitem:after { content: ","; padding-right: 5px;  }');
  css.insert('.tagsitem:last-of-type:after { content: ""  }');

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <html lang="fr" />
          <title>{frontmatter.metaTitle}</title>
          <meta name="description" content={frontmatter.metaDescription} />
          <meta
            property="og:url"
            content={`https://www.dr-kron.com${frontmatter.path}`}
          />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={frontmatter.title} />
          <meta
            property="og:description"
            content={frontmatter.metaDescription}
          />
          <meta
            property="og:image"
            content={`https://www.dr-kron.com${frontmatter.image.childImageSharp.fluid.src}`}
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="455" />

          <meta property="og:locale" content="fr_FR" />
          <meta property="fb:app_id" content="402235436975681" />

          {/* Breadcrumbs for interventions */}
          {frontmatter.tags[0] !== "cabinet" &&
            frontmatter.tags[0] !== "actualites" && (
              <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Interventions",
              "item": "https://www.dr-kron.com/interventions/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "${
                frontmatter.tags[0].charAt(0).toUpperCase() +
                frontmatter.tags[0].slice(1)
              }",
              "item": "https://www.dr-kron.com/${frontmatter.tags[0].replace(
                /\s+/g,
                "-"
              )}/"
            },{
              "@type": "ListItem",
              "position": 3,
              "name": "${frontmatter.title}",
              "item": "https://www.dr-kron.com${frontmatter.path}"
            }]
        }
    `}</script>
            )}
          {/* Breadcrumbs for actualites */}
          {frontmatter.tags[0] === "actualites" && (
            <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Actualités",
              "item": "https://www.dr-kron.com/actualites/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "${frontmatter.title}",
              "item": "https://www.dr-kron.com${frontmatter.path}"
            }]
        }
    `}</script>
          )}
          {/* Breadcrumbs for cabinet */}
          {frontmatter.tags[0] === "cabinet" && (
            <script type="application/ld+json">{`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "${frontmatter.title}",
              "item": "https://www.dr-kron.com${frontmatter.path}"
            }]
        }
    `}</script>
          )}
        </Helmet>
        <Menu />

        <Wrapper>
          <article>
            <Container css={{ padding: `0 15px !important` }}>
              <Row>
                <Col>
                  <div className="page-post">
                    {frontmatter.tags[0] === "cabinet" && <p>&nbsp;</p>}

                    {frontmatter.tags[0] !== "cabinet" && (
                      <Link
                        to={`/${frontmatter.tags[0].replace(/\s+/g, "-")}/`}
                        css={{ textDecoration: `none` }}
                      >
                        <Tag>{frontmatter.tags[0]}</Tag>
                      </Link>
                    )}

                    <Title>{frontmatter.title}</Title>

                    {frontmatter.image !== "none" && (
                      <Img
                        fluid={frontmatter.image.childImageSharp.fluid}
                        css={{
                          marginBottom: `2rem`,
                          "@media only screen and (max-width: 600px)": {
                            marginLeft: `calc(50% - 49.99vw)`,
                            marginRight: `calc(50% - 49.99vw)`,
                            marginBottom: `1em`,
                          },
                        }}
                        alt={frontmatter.title}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                  {/* Breadcrumbs for interventions */}
                  {frontmatter.tags[0] !== "cabinet" &&
                    frontmatter.tags[0] !== "actualites" && (
                      <Breadcrumb>
                        <BreadcrumbItem>
                          <Link
                            to="/"
                            {...cssBreadcrumbLink}
                            title="Page d'accueil"
                          >
                            <FaHome
                              css={{
                                fontSize: `1.2em`,
                                paddingBottom: `.2em`,
                                transform: `translateY(5px)`,
                              }}
                            />
                          </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <Link to="/interventions/" {...cssBreadcrumbLink}>
                            Interventions
                          </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <Link
                            {...cssBreadcrumbLink}
                            to={`/${frontmatter.tags[0].replace(/\s+/g, "-")}/`}
                          >
                            {frontmatter.tags[0].charAt(0).toUpperCase() +
                              frontmatter.tags[0].slice(1)}
                          </Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{frontmatter.title}</BreadcrumbItem>
                      </Breadcrumb>
                    )}
                  {/* Breadcrumbs for actualites */}
                  {frontmatter.tags[0] === "actualites" && (
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link
                          to="/"
                          {...cssBreadcrumbLink}
                          title="Page d'accueil"
                        >
                          <FaHome
                            css={{
                              fontSize: `1.2em`,
                              paddingBottom: `.2em`,
                              transform: `translateY(5px)`,
                            }}
                          />
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to="/actualites/" {...cssBreadcrumbLink}>
                          Actualites
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>{frontmatter.title}</BreadcrumbItem>
                    </Breadcrumb>
                  )}
                  {/* Breadcrumbs for cabinet */}
                  {frontmatter.tags[0] === "cabinet" && (
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link
                          to="/"
                          {...cssBreadcrumbLink}
                          title="Page d'accueil"
                        >
                          <FaHome
                            css={{
                              fontSize: `1.2em`,
                              paddingBottom: `.2em`,
                              transform: `translateY(5px)`,
                            }}
                          />
                        </Link>
                      </BreadcrumbItem>

                      <BreadcrumbItem>{frontmatter.title}</BreadcrumbItem>
                    </Breadcrumb>
                  )}

                  <div
                    className="page-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <Social path={frontmatter.path} title={frontmatter.title} />
                  {frontmatter.tags[0] !== "cabinet" && (
                    <Tags>
                      <div
                        css={{
                          fontSize: `0.85em`,
                          fontWeight: `600`,
                          textTransform: `uppercase`,
                          display: `inline-block`,
                          paddingRight: `5px`,
                        }}
                      >
                        Catégories&nbsp;:
                      </div>
                      {frontmatter.tags.map((tag) => (
                        <Link
                          to={`/${tag.replace(/\s+/g, "-")}/`}
                          css={{
                            textDecoration: `none`,
                            fontSize: `.85em`,
                            color: `#3c4d68`,
                            fontWeight: `500`,
                          }}
                          className="tagsitem"
                        >
                          <TagsItem>{tag}</TagsItem>
                        </Link>
                      ))}
                    </Tags>
                  )}
                  <Bio />
                </Col>
                <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                  <aside>
                    <ContactSidebar about={frontmatter.about} />
                    <AdresseSidebar />
                    <Accordion />
                    <AvantApresBlock />
                    <KeybodyBlock />
                    <VideoBlock />
                    <NewsBlock />
                    <ASavoir />
                  </aside>
                </Col>
              </Row>
            </Container>
          </article>
        </Wrapper>
        {frontmatter.tags[0] === "actualites" && (
          <Carrousel other={autresPages} />
        )}

        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query NewsPostByPath($path: String!, $tag: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        tags
        about
        dateDisplay
        metaTitle
        metaDescription
        image {
          absolutePath
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 952) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 28
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          frontmatter {
            shortTitle
            excerpt
            path
            image {
              childImageSharp {
                fluid(maxWidth: 367) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
